










































import { Component, Vue } from 'vue-property-decorator'
import QRCode from 'qrcodejs2'
import {
  queryPayStatus,
} from "@/api";

interface IProduct {
  id: number
  createTime: string
  isChecked: boolean
  partnumber: string
  mft: string
  description: string
  amount: number
  time: string
  rsp: Array<string>
  stockstatus: Array<string>
  unitPrice: number
  totalPrice: number
  materialId : number
  isValid: number

}

@Component({
  components: {},
})
export default class WxPay extends Vue {
  checkAll = false
  checkedProducts: Array<number> = []
  productIds: Array<number> = []
  isIndeterminate = false
  products: Array<IProduct> = []
  totalPrice = 0
  orderNo = ""
  price="" 
  orderTimer = null;

        
  beforeDestroy() {
    clearInterval(this.orderTimer);        
    this.orderTimer = null;
  }
  mounted(): void {
    // this.getCart();
    //  console.log(this.products.length)
    // this.selectByUserId();
   
    let orderNo   =  this.$route.query.orderNo.toString()   ;
    let price   =  this.$route.query.price.toString()   ;
    this.orderNo = orderNo;
    this.price = price;
    // console.log(this.orderNo)
    // console.log(this.price)
    this.queryPayStatus();
    let codeUrl  =  this.$route.query.codeUrl   ;
    this.creatQrCode(codeUrl);

    this.orderTimer = setInterval(() => {
      // clearInterval(this.orderTimer);
      this.queryPayStatus();
    }, 3000);
  }
  creatQrCode(codeUrl : any) {
        new QRCode(this.$refs.qrCodeUrl, {
            text: codeUrl, // 需要转换为二维码的内容
            width: 276,
            height: 276,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H,
        })
    }
  openFullScreen() {
        const loading = this.$loading({
          lock: true,
          text: '由于超时未支付,交易已关闭,请关闭该页面重新下单',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.9)'
        });
        // setTimeout(() => {
        //   loading.close();
        // }, 2000);
      }
  queryPayStatus(): void{
  
    queryPayStatus(this.orderNo).then(res => {
      
      if(res.status == 200){
          if(res.data.code == 200){
            
            // console.log(res.data.data)
            if(res.data.data == 1){
              clearInterval(this.orderTimer);
              this.$router.replace("order");
            }else if(res.data.data == 2){
              this.openFullScreen();
            }
          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  };


}
